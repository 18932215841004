<template>
    <modal class="saveCouponsPayModel" :isShow="isShow">
        <div class="header">卡券支付<span class="close" @click="hide()">×</span></div>
        <div ref="code">
        <div class="The-head">
            请输入或扫码：
            <div class="from-input" >
                <i class="iconfont icon-a-saoma13x"></i>
                <input type="text" ref="input" class="key-focus" data-model='idCode' v-model="idCode" @keyup.enter="readCard()" />
            </div>
            <button class="btn" @click="readCard()">查询</button>
            <span class="radio-st" >
            <label class="checkbox" @click="radioClick(1)"><input type="radio" name="_fa_radio" :checked="tickChecke" />
            <i class="iconfont icon-a-danxuanweixuanzhong"></i>按券码查询</label></span>
            <span class="radio-st"  >
                <label class="checkbox" @click="radioClick(2)"><input type="radio" name="_fa_radio" :checked="mobleChecke" />
                <i class="iconfont icon-a-danxuanweixuanzhong"></i>按手机号查询</label>
            </span>
            <div>
               <!-- <label><input type="radio" name="_fa_radio" />
                <i class="iconfont icon-a-danxuanweixuanzhong"></i></label>  -->
            </div>
        </div>
        <div class="modal-body">
            <div class="clearfix">
                <div class="left-box">
                    <div class="panel">
                        <div class="panel-heading">券列表</div>
                        <div class="panel-body item-box clearfix" v-scroll-anime="{up:'left-order-up',down:'left-order-down',liClass:'.item-li',isRow:false}">
                            <div class="item-li coupons" :class="{selected:item._selected}" @click="onTicket(item)"
                            v-for="item in ticketInfos" :key="item">
                                <div class="coupons-name">
                                    <div class="coupon-left" :class="{selected:item.Check==true}">
                                        <div class="left-txt">
                                            <span class="m">¥</span>
                                            <span class="y">{{item.Coupon_Amount}}</span>
                                        </div>
                                    </div>
                                    <div class="coupon-right" :class="{selected:item.Check==true}">
                                        <div class="coupon-top">
                                            <span class="tk-txt">{{item.Coupon_Name}}</span>
                                            <span class="tk-time">{{item.Coupon_Code}}</span>
                                            <span class="tk-time">有效期:{{new Date(item.Start_Time).Format("yyyy-MM-dd")}}至{{new Date(item.End_Time).Format("yyyy-MM-dd")}}</span>
                                        </div>
                                        <span class="cou-ge"></span>
                                        <div class="coupon-bottom">
                                            详细信息
                                            <div class="triangle" v-show="item.extendShow==false" @click="extenClick(item)"></div>
                                            <div class="triangles" v-show="item.extendShow==true" @click="extenClick(item)"></div>
                                        </div>
                                        <div class="extend" v-show="item.extendShow==true">
                                            <span style="margin-left:-120px;" v-show="item.Consume_MinMoney>0">使用说明</span>
                                            <span class="ex-span" v-show="item.Consume_MinMoney>0" >消费满{{item.Consume_MinMoney}}元可使用此券</span>
                                        </div>
                                        <i class="iconfont icon-gou-fine"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item-operation">
                            <div class="float-right">
                                <div class="bnt-fangxiang" ref="left-order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                <div class="bnt-fangxiang" ref="left-order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                    <div class="box-bottom">
                        <div><span class="btm-span">待支付金额:</span>
                            <span class="btm-span-money">
                                {{invoMoney}}
                            </span>
                        </div>
                        <div class="tick-div"><span class="tick-span">券抵扣:</span><span class="tick-money">{{Number(this.tickDeduMoney).toFixed(2)}}</span></div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()"><i v-show="isLock" class="iconfont icon-suo"></i>取消</button>
            <button class="btn btn-border" @click="select()">重新查询核销结果</button>
            <button class="btn btn-confirm" @click="confirm()"><i v-show="isLock" class="iconfont icon-suo"></i>确定</button>
        </div>
        <!--券数量修改-->
        <modal-load :isShow="numShow" :isUnload="true">
            <num-modify-model :isShow="numShow" @numCloseModel="numCloseModel" @cashConfirm="cashConfirm"></num-modify-model>
        </modal-load>
    </modal>
</template>
<script>

import { deepClone,initKeyBoard } from '/src/common' 
import { httpAES } from '/src/services'
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus"
import numModifyModel from '../numModifyModel/numModifyModel.vue'

/**现金券 或 商品券 选择 */
export default {
    name:"saveCouponsPayModel",
    components:{
        numModifyModel
    },
    props:{
        isShow:Boolean,
        orderInfo:Object,
        unionEatAutoID:Object,
        choicePayType:Object,
        unionType:Boolean
    },
    data(){
        return {
            //是否锁 不让关闭弹层
            isLock:false,
            numShow:false,
            idCode:'',
            /**券数据 */
            ticketInfos:[],
            /**选中的券 */
            selectCoupon:[],
            /**当前选中的券 */
            presentCoupon:'',
            /**菜品信息 */
            posFoodData:'',
            tickChecke:true,
            mobleChecke:false,
            /**保存订单支付返回记录 */
            payPreviewData:'',
            /**交易记录 */
            Payments:[],
            /**临时订单数据 */
            memberorderInfo:'',  
            unPaidMoney:0,//待付金额
            tickDeduMoney:0,//券抵扣金额
            amountMoney:0, //代金券抵扣金额
            userInfo:'', //登录用户
        }
    },
    mounted(){
        //菜品数据结构
        this.$cacheData.PosFoods().then((d)=>{
            this.posFoodData = d.data
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
        })
        this.$nextTick(()=> {
           initKeyBoard.call(this,this.$refs.code);
        })
        this.init();
    }, 
    computed:{
        //结账金额
        invoMoney(){
            let invoMoney=0;
            if(Number(this.unPaidMoney).toFixed(2) - Number(this.tickDeduMoney).toFixed(2)>0){
                invoMoney = Number(Number(this.unPaidMoney) - Number(this.tickDeduMoney)).toFixed(2)
            }
            return invoMoney
        }
    },
    watch: {
        orderInfo:{
            immediate:true,
            handler(vla){
                this.init();
            }
        },
        isShow(newVal) {
            if(newVal){
                this.init();
            }else{
                if(this._timePay) clearTimeout(this._timePay);
            }
        }
    },
    methods:{
        init(){
            this.isLock=false;
            this.idCode=''
            this.ticketInfos=[],this.Payments=[],this.payPreviewData=''
            this.selectCoupon=[],this.tickChecke=true,this.mobleChecke=false,this.tickDeduMoney=0
            this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
            //合并联台的订单信息
            // if(this.unionType==true){
            //     this.memberorderInfo.UnionOrderInfoList.map(union=>{
            //         union.Order_ConsumeList.map(unOrder=>{
            //             this.memberorderInfo.Order_ConsumeList.push(unOrder)
            //         })
            //     })
            // }
            // if(this.memberorderInfo.Order_ConsumeList){
            //     this.memberorderInfo.Order_ConsumeList.map((order)=>{
            //         order = Object.assign(order,{isCoupon:false,CouponNumber:0})
            //     })
            // }
            this.unPaidMoney = this.memberorderInfo.UnPaid_Money
            this.userInfo=this.$auth.getUserInfo()
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
        },
        //重新查询核销结果
        select(){
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                User_ID:this.userInfo?.User_ID,  //用户id
                Eat_AutoID:this.memberorderInfo.Eat_AutoID, //结账单ID
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Operate_EquipmentID:this.userInfo?.Default_ChannelID, //设备id
                Pos_StationName:this.userInfo?.Site_Name, //站点名称
                Timestamps:this.memberorderInfo.Timestamps.toString(), //账单时间戳
            }
            const loading = this.$loading({
                text: "查询支付结果中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.RequeryThePaymentResult",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    if(data.ResponseBody?.Timestamps){
                        this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString();
                    }
                    //订单时间戳已变更需要去刷新订单时间
                    if(data.ResponseHeader.ResultCode==3002){
                        this.$emit("refreshOrder")
                    }
                }else{
                    if(data.ResponseHeader.ResultDesc){
                        ElMessage.success({ message:data.ResponseHeader.ResultDesc});
                    }
                    this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString();
                    return;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        radioClick(type){
            if(type==1){
                this.tickChecke=true
                this.mobleChecke=false
            }else{
                this.tickChecke=false
                this.mobleChecke=true
            }
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
        },
        extenClick(item){
            if(item.extendShow==true){
                item.extendShow=false
            }else{
                item.extendShow=true
            }
        },
        readCard(){
            if(this.isLock) return;
            if(!this.idCode){
                this.$message.warning('查询码不能为空');
                return
            }
            //获取平台券信息
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Platform_Type:4, //平台类型 1:抖音 2:美团 3:口碑 4: 翼码
                Dynamic_Code:this.idCode,
                Dynamic_Type:'',//查询码类型 ，mobile : 按手机号查询 ，不传按默认查询类型
            }
            if(this.mobleChecke==true){
                param.Dynamic_Type='mobile'
            }
            const loading = this.$loading({
                text: "获取券信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CouponPlatform.GetCouponInfo",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc)
                }else{
                    this.ticketInfos = data?.ResponseBody
                    this.ticketInfos.map(tick=>{
                        tick = Object.assign(tick,{extendShow:false,Check:false,deDuMoney:0})
                    })
                    this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
                    //合并联台的订单信息
                    if(this.unionType==true){
                        this.memberorderInfo?.UnionOrderInfoList.map(union=>{
                            union.Order_ConsumeList.map(unOrder=>{
                                this.memberorderInfo.Order_ConsumeList.push(unOrder)
                            })
                        })
                    }
                    if(this.memberorderInfo.Order_ConsumeList){
                        this.memberorderInfo.Order_ConsumeList.map((order)=>{
                            order = Object.assign(order,{isCoupon:false,CouponNumber:0})
                        })
                    }
                    this.memberorderInfo.Order_ConsumeList.sort(this.compare('Eat_CheckPrice',''));
                    this.unPaidMoney = this.memberorderInfo.UnPaid_Money
                    this.selectCoupon=[],this.tickDeduMoney=0
                    return
                    
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        //关闭
        hide(){
            if(!this.isLock){
                this.$emit("closeModel");
            }
            // if(this.payPreviewData){
            //     this.$emit("savePayClose",this.Payments,this.payPreviewData.ResponseBody.Timestamps,this.selectCoupon)
            // }else{
            //     this.$emit("savePayClose",this.Payments,'')
            // }
        },
        //点击券
        onTicket(item){
            if(this.isLock) return;
            if(item.Check==true){
                this.selectCoupon.some((set,index)=>{
                    if(set.Coupon_Id==item.Coupon_Id){
                       if(set?.Check==true){
                            if(set.Exchange_Num){
                                 this.tickDeduMoney -= Number(set.deDuMoney) * Number(set.Exchange_Num)
                            }else{
                                 this.tickDeduMoney -= Number(set.deDuMoney)
                            }
                            if(set?.CouponNum){
                                this.tickDeduMoney -= Number(set.deDuMoney) * (Number(set.CouponNum)-1)
                            }
                            this.selectCoupon.splice(index, 1);
                            item.Check=false
                        }
                        return true
                    }
                })
                this.memberorderInfo.Order_ConsumeList.some(order=>{
                    if(item.Coupon_Type==3){
                        if(item?.Exchange_FoodCodes.indexOf(order.EAT_XFCode)!=-1){
                            if(order.isCoupon==true){
                                order.isCoupon=false
                                order.CouponNumber -=1
                                return true
                            }
                        }
                    }
                })
            }else{
                //券的最低消费额，0 不限制
                if(item.Consume_MinMoney!=0){
                    //this.orderInfo.Eat_CheckMoney
                    if(this.orderInfo.Payable_Money<item.Consume_MinMoney){
                        this.$message.warning('账单未达到使用该券的金额!');
                        return
                    }
                }
                //时间段控制 0所有时段,1指定时段
                let date = new Date(); //当前时间
                if(new Date(item.Start_Time)>date || date > new Date(item.End_Time)){
                    this.$message.warning('不在该券可使用的时间范围内!');
                    return
                }
                //1.代金券
                if(item.Coupon_Type==1){
                    item.Check=true
                    item.deDuMoney = item.Coupon_Amount
                    this.CoupondeduMoney(item,'','')
                }
                //2.折扣价
                if(item.Coupon_Type==2){
                    item.Check=true
                    item.deDuMoney = Number(Number(this.unPaidMoney)-(Number(this.unPaidMoney) * (item.Discount_Rate / 100)).toFixed(2))
                    this.CoupondeduMoney(item,'','')
                }
                //3.商品券
                if(item.Coupon_Type==3){
                    //FoodRange_Type适用类型 1:通用券，所有商品适用，2:部分商品券
                    if(item.FoodRange_Type==1){
                        this.memberorderInfo.Order_ConsumeList.map(order=>{
                            if(order.isCoupon==false && order.Food_Type!=4 && order.Eat_Number>0 && order.Is_Gift==false && order.Eat_Number>order.Exchanged_Number){
                                if(item.Food_Weight==0){
                                    item.deDuMoney = Number(order.Eat_CheckPrice)
                                    item.Check=true
                                    this.CoupondeduMoney(item,order,'')
                                    if(order.CouponNumber==0 || order.CouponNumber<order.Eat_Number){
                                        order.CouponNumber += 1
                                    }
                                    if(order.CouponNumber>=order.Eat_Number){
                                        order.isCoupon=true
                                    }
                                }else{
                                    let _weight=this.commWeight(item,order)
                                    item.deDuMoney = Number(order.Eat_CheckPrice)
                                    item.Check=true
                                    this.CoupondeduMoney(item,order,_weight)
                                    if(order.CouponNumber==0 || order.CouponNumber<order.Eat_Number){
                                        order.CouponNumber += _weight
                                    }
                                    if(order.CouponNumber>=order.Eat_Number){
                                        order.isCoupon=true
                                    }
                                }
                            }
                        })
                    }
                    if(item.FoodRange_Type==2){
                        let title =''
                        let _types = 0
                        this.memberorderInfo.Order_ConsumeList.some(order=>{
                            if(order.isCoupon==false && order.Food_Type!=4){
                                if(item.Exchange_FoodCodes.indexOf(order.EAT_XFCode)!=-1 && order.Eat_Number>0 && order.Is_Gift==false && order.Eat_Number>order.Exchanged_Number){
                                    if(item.Food_Weight==0){
                                        _types=1
                                        item.deDuMoney = Number(order.Eat_CheckPrice)
                                        item.Check=true
                                        if(order.CouponNumber==0 || order.CouponNumber<order.Eat_Number){
                                            order.CouponNumber += 1
                                        }
                                        if(order.CouponNumber>=order.Eat_Number){
                                            order.isCoupon=true
                                        }
                                        this.CoupondeduMoney(item,order,'')
                                    }else{
                                        _types=1
                                        let _weight=this.commWeight(item,order)
                                        item.deDuMoney = Number(order.Eat_CheckPrice)
                                        item.Check=true
                                        if(order.CouponNumber==0 || order.CouponNumber<order.Eat_Number){
                                            order.CouponNumber += _weight
                                        }
                                        if(order.CouponNumber>=order.Eat_Number){
                                            order.isCoupon=true
                                        }
                                        this.CoupondeduMoney(item,order,_weight)
                                    }
                                    
                                }else{
                                    if(order.Eat_Number<=order.Exchanged_Number){
                                        title = '该券对应的菜品已使用过券,已不满足条件'
                                    }else{
                                        title = '未找到编号'+item.Exchange_FoodCodes+'的菜品'
                                    }
                                }
                                if(_types==1){
                                    return true
                                }
                            }
                        })
                        if(_types==0){
                            if(title==''){
                                title='没有满足条件的菜品'
                            }
                            this.$message.warning(title);
                            return
                        }
                    }
                }
                //如果IsVerifyWithInputNum为True则弹出数量选择
                if(item.IsVerifyWithInputNum==true){
                    this.numShow=true
                    this.presentCoupon=item
                }
            }
        },
        //商品券兑换商品重量(单位：斤），0 不限制
        commWeight(item,order){
            if(item.Food_Weight>=order.Eat_Number){
                return order.Eat_Number
            }else if(item.Food_Weight<=order.Eat_Number-order.Exchanged_Number){
                return item.Food_Weight
            }else if(item.Food_Weight>order.Eat_Number-order.Exchanged_Number){
                return Number(Number(order.Eat_Number-order.Exchanged_Number).toFixed(2))
            }
        },
        //deduMoney变动
        CoupondeduMoney(item,order,weight){
            this.selectCoupon.push(item)
            this.tickDeduMoney=0
            this.selectCoupon.map(sele=>{  
                if(sele.Check==true && !sele?.ConsumeID){
                    if(order){
                        if(item.Food_Weight!=0){
                            sele = Object.assign(sele,{ConsumeID:order.Eat_ConsumeID,Exchange_Num:weight})
                        }else{
                            let _num=0
                            if(order.Exchanged_Number!=0){
                                if(order.Eat_Number-order.Exchanged_Number>1){
                                    _num=1
                                }else{
                                    _num = order.Eat_Number-order.Exchanged_Number
                                }
                            }else{
                                if(order.Eat_Number<1){
                                    _num = order.Eat_Number
                                }else{
                                    _num = 1
                                }
                            }
                            sele = Object.assign(sele,{ConsumeID:order.Eat_ConsumeID,Exchange_Num:_num})
                        }
                    }else{
                        sele = Object.assign(sele,{Exchange_Num:1})
                    }
                }
                if(sele.Exchange_Num){
                    this.tickDeduMoney += sele.deDuMoney * sele.Exchange_Num
                }else{
                    this.tickDeduMoney += sele.deDuMoney
                }
            })
        },
        //确定支付
        confirm(){
            if(this.isLock) return;
            if(this.selectCoupon.length==0){
                this.$message.warning('请先选择优惠券!');
                return
            }
            /**支付方式 */
            let _unpaiMoney = Number(this.unPaidMoney)
            let _payment=[]
            this.selectCoupon.map(tick=>{
                let _choice={
                    Coupon_AutoId:tick.Coupon_TemplateId,
                    Coupon_Code:tick.Coupon_Code,
                    Coupon_Name:tick.Coupon_Name,
                    Coupon_Kind:tick.Coupon_Type,
                    Coupon_Num:1,
                    Coupon_CostPrice:tick.Merchant_FactAmount,
                    Exchange_ConsumeID:tick?.ConsumeID,
                    Exchange_Num:tick?.Exchange_Num,
                    Coupon_VerifyToken:tick.Coupon_VerifyToken,
                    Scene_Type:1,
                    External_UserId:tick?.External_UserId,
                    Customer_Mobile:tick?.Customer_Mobile,
                    Channel_ID:tick?.Channel_ID
                }
                if(tick?.CouponNum){
                    _choice.Coupon_Num=Number(tick?.CouponNum)
                }
                //多收金额
                let OverChargeMoney=0
                if(Number(tick.deDuMoney) * 1 >= _unpaiMoney){
                     OverChargeMoney = Number(tick.deDuMoney) * 1 - _unpaiMoney
                     _unpaiMoney=0
                }else{
                    _unpaiMoney = _unpaiMoney - Number(tick.deDuMoney) * 1
                }
                let Payment_Money=Number(tick.deDuMoney) * tick.Exchange_Num; //商品券 支付金额
                if(tick.Coupon_Type==1){//代金券 支付金额
                    Payment_Money=Number(tick.deDuMoney) * _choice.Coupon_Num;
                }else if(tick.Coupon_Type==2){
                    Payment_Money=Number(tick.deDuMoney);
                }

                _payment.push({
                    Payment_ID:this.choicePayType.PAY_ID,  //支付方式ID
                    Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                    Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                    Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                    Payment_Money: Payment_Money, //支付金额
                    OverCharge_Money:OverChargeMoney,  //多收金额
                    Payment_FactMoney:0, //计营业额
                    Qr_Code:'', //扫码付款的码值
                    Card_No:'', //卡号
                    CouponInfo:_choice, //券的详情
                })
            })
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID, //登陆用户ID
                Eat_AutoID: this.memberorderInfo.Eat_AutoID,//结账单ID
                UnionOrders:this.unionEatAutoID,//联台订单ID集合
                Timestamps:this.memberorderInfo.Timestamps.toString(), //账单时间戳
                Operator_Name:this.userInfo?.Login_Name,//操作员
                Eat_DeskName:this.memberorderInfo.Eat_DeskName, //桌牌号
                Service_Charge:this.memberorderInfo.Service_Money,  //服务费
                MantissaAdjustment_Money:this.memberorderInfo.Mantissa_Money,  //尾数调整金额
                Member:'',//会员信息
                Payments:_payment, //支付方式列表
            }
            this.$emit("publicPayPreview",param,'savePayShow',this.selectCoupon,(d)=>{
                if(d.ResponseHeader.ResultCode==4001){//重新查询
                    this.timeoutPayment();
                }
            })
        },
        cashConfirm(allNum){
            this.numShow=false
            this.$keyavail=false
            this.selectCoupon.forEach(tick=>{
                if(tick.Coupon_Id==this.presentCoupon.Coupon_Id){
                    if(!tick?.CouponNum){
                        tick = Object.assign(tick,{CouponNum:allNum})
                    }else{
                        tick.CouponNum=allNum
                    }
                    this.tickDeduMoney += tick.deDuMoney *(Number(tick.CouponNum)-1)
                }
            })
        },
        numCloseModel(){
            this.numShow=false
            this.$keyavail=false
            this.onTicket(this.presentCoupon)
            this.presentCoupon=''
        },
        /**数组排序 */
        compare(attr,Time) {
            return function(a,b){
                var val1 = a[attr];
                var val2 = b[attr];
                var Times1 = a[Time];
                var Times2 = b[Time];
                return val2 - val1 || Times1 - Times2;
            }
        },
        /**定时查看结果 */
        timeoutPayment(){
            if(this._timePay) clearTimeout(this._timePay);
            this.isLock=true;//锁住不退出
            this._timePay=setTimeout(()=>{
                if(this.isLock){
                    this.isLock=false;
                    this.select();
                }
            },5000);
        },
        create(){
            if(this._timePay) clearTimeout(this._timePay);
        }
    }
}
</script>

<style lang="sass">
@import "./saveCouponsPayModel.scss";
</style>