<template> 
    <div class="modal fade"  :class="showClass" :style="showStyle" @mousedown="mousedown">
        <div ref="tab" tabindex="-1"></div>
        <div class="modal-dialog" @click="(e)=>{if(isMask)e.stopPropagation()}">
            <div class="modal-content">
                <slot v-if="(isIfShow && showStyle!='') || !isIfShow"></slot>
            </div>
        </div>
    </div>
</template>

<script>
let modalId=1;
export default {
    name: 'modal',
    emits:["close"],
    data() {
        let id="modal_"+(modalId++);
        return {
            showClass:"",
            showStyle:"",
            modalData:{
                modalId:id,
                isShow:this.isShow
            }
        }
    },
    props:{
        isShow:Boolean,
        isMask:{
            default:false,
            type:Boolean
        },
        isIfShow:{//是否允许 每次显示弹层时重新渲染dom
            default:false,
            type:Boolean
        }
    },
    mounted(){ 
        this.modalData.isShow=this.isShow;
        let parent=this.$parent?.$parent
        if(parent?.$?.type?.name=="modalLoad"){
            parent=parent.$parent;
        }
        if(parent?.$?.type?.name=="modalList"){
            parent.add(this.modalData);//弹层显示了
        }
        this.init();
    },
    watch:{
        isShow() {
            this.modalData.isShow=this.isShow;
            this.init();
        }
    },
    methods:{
        init(){
            if(this.isShow) {
                this.showStyle="display:block";
                if(this.time){
                    clearTimeout(this.time);
                }
                this.time= setTimeout(()=>{ this.showClass="show";},100);
                this.$nextTick(()=>{
                    this.$refs.tab.focus();
                })
            }else{
                this.showClass="";
                if(this.time){
                    clearTimeout(this.time);
                }
                this.time=setTimeout(()=>{
                    this.showStyle="";
                    this.$emit("close");
                },150);
            }
        },
        close(){
            this.showClass="";
            if(this.time){
                clearTimeout(this.time);
            }
            this.time=setTimeout(()=>{
                this.showStyle="";
                this.$emit("close");
            },150);
        },
        show(){
            
        },
        mousedown(e){
            if(!(e.target.nodeName=="INPUT" || e.target.nodeName=="TEXTAREA")){//只有不是 输入框就 阻止默认事件
                e.preventDefault();
            }
            console.log(e)
        }
    },
    unmounted(){
        if(this.time){
            clearTimeout(this.time);
        }

        let parent=this.$parent?.$parent
        if(parent?.$?.type?.name=="modalLoad"){
            parent=parent.$parent;
        }
        if(parent?.$?.type?.name=="modalList"){
            parent.remove(this.modalData);//弹层显示了
        }
    }
}
</script>

<style>

</style>